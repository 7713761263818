import { Controller } from "@hotwired/stimulus"
import { useRender, html } from "stimulus-lit"
import { useDebounce } from "stimulus-use"
import { getPlacePredictions, initializeService } from "./AutoCompleteService"
import type { SlInput } from "@shoelace-style/shoelace"
import type { SlAutocomplete } from "custom_elements/sl-autocomplete"

export class AddressSelectController extends Controller<SlAutocomplete> {
  static debounces = ["slInput"]

  static values = {
    addresses: Array,
    apiToken: String,
  }

  declare addressesValue: Array<string>
  declare apiTokenValue?: string

  connect() {
    useRender(this)
    useDebounce(this)
    initializeService(this.apiTokenValue)
  }

  async slInput(event: CustomEvent) {
    const { value } = event.target as SlInput

    if (value.length > 0) {
      try {
        this.element.loading = true
        const result = await getPlacePredictions(value)
        this.addressesValue = result.predictions.map(({ description }) => description)
      } finally {
        this.element.loading = false
      }
    } else {
      this.addressesValue = []
    }
  }

  render() {
    return this.addressesValue.map((address) => html`<sl-option value=${address}>${address}</sl-option>`)
  }
}
