import { Controller } from "@hotwired/stimulus"
import { SlButton } from "@shoelace-style/shoelace"

export class ConsultancySearchController extends Controller<HTMLFormElement> {
  static targets = ["resetButton"]
  declare readonly resetButtonTarget: SlButton

  search(_event: CustomEvent) {
    this.resetButtonTarget.disabled = false
    this.element.requestSubmit()
  }
}
