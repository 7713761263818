import { html, LitElement, css } from "lit"
import { customElement, property } from "lit/decorators.js"
import { classMap } from "lit/directives/class-map.js"

@customElement("sl-nav-link")
export default class SlNavLink extends LitElement {
  static styles = css`
    :host {
      box-sizing: border-box;
    }

    :host *,
    :host *::before,
    :host *::after {
      box-sizing: inherit;
    }

    [hidden] {
      display: none !important;
    }

    :host {
      display: block;
    }

    .nav-link {
      position: relative;
      display: flex;
      align-items: stretch;
      font-family: var(--sl-font-sans);
      font-size: var(--sl-font-size-small);
      font-weight: var(--sl-font-weight-normal);
      line-height: var(--sl-line-height-normal);
      letter-spacing: var(--sl-letter-spacing-normal);
      color: var(--sl-color-neutral-700);
      padding: var(--sl-spacing-2x-small) var(--sl-spacing-small);
      transition: var(--sl-transition-fast) fill;
      user-select: none;
      white-space: nowrap;
      cursor: pointer;
      margin: var(--sl-spacing-2x-small) var(--sl-spacing-medium) var(--sl-spacing-2x-small) var(--sl-spacing-medium);
      border-radius: var(--sl-border-radius-large);
      text-decoration: none;
    }

    .nav-link .nav-link__label {
      flex: 1 1 auto;
    }

    .nav-link .nav-link__prefix {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
    }

    .nav-link .nav-link__prefix ::slotted(*) {
      margin-inline-end: var(--sl-spacing-x-small);
    }

    .nav-link .nav-link__suffix {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
    }

    .nav-link .nav-link__suffix ::slotted(*) {
      margin-inline-start: var(--sl-spacing-x-small);
    }

    :host(:focus) {
      outline: none;
    }

    :host(:hover:not([aria-disabled="true"])) .nav-link,
    :host(:focus-visible:not(.sl-focus-invisible):not([aria-disabled="true"])) .nav-link {
      outline: none;
      background-color: var(--sl-color-neutral-100);
      color: var(--sl-color-neutral-700);
    }

    .nav-link .nav-link__check,
    .nav-link .nav-link__chevron {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.5em;
      visibility: hidden;
    }
    .nav-link--checked .nav-link__check,
    .nav-link--has-submenu .nav-link__chevron {
      visibility: visible;
    }
  `

  @property({ type: String }) href = ""

  render() {
    return html`
      <a part="base" class=${classMap({ "nav-link": true })} href="${this.href}">
        <span part="prefix" class="nav-link__prefix">
          <slot name="prefix"></slot>
        </span>

        <span part="label" class="nav-link__label">
          <slot></slot>
        </span>

        <span part="suffix" class="nav-link__suffix">
          <slot name="suffix"></slot>
        </span>

        <span class="nav-link__chevron">
          <sl-icon name="chevron-right" library="system" aria-hidden="true"></sl-icon>
        </span>
      </a>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "sl-nav-link": SlNavLink
  }
}
