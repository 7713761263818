import { Application } from "@hotwired/stimulus"
import { AddressSelectController } from "./address_select_controller"
import { ConsultancyDrawerController } from "./consultancy_drawer_controller"
import { ConsultancySearchController } from "./consultancy_search_controller"
import { FileUploadController } from "./file_upload_controller"
import { MultiInputController } from "./multi_input_controller"
import { SlDialogController } from "./sl_dialog_controller"

const application = Application.start()

application.debug = false
Stimulus = application

application.register("address-select", AddressSelectController)
application.register("consultancy-drawer", ConsultancyDrawerController)
application.register("consultancy-search", ConsultancySearchController)
application.register("file-upload", FileUploadController)
application.register("multi-input", MultiInputController)
application.register("sl-dialog", SlDialogController)

// Eager load all controllers defined in the import map under controllers/**/*_controller
// import { eagerLoadControllersFrom } from "@hotwired/stimulus-loading"
// eagerLoadControllersFrom("controllers", application)

// Lazy load controllers as they appear in the DOM (remember not to preload controllers in import map!)
// import { lazyLoadControllersFrom } from "@hotwired/stimulus-loading"
// lazyLoadControllersFrom("controllers", application)
