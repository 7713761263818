import { html, LitElement, css } from "lit"
import { customElement, property, query, state } from "lit/decorators.js"
import { classMap } from "lit/directives/class-map.js"
import { styleMap } from "lit/directives/style-map.js"
import { scrollIntoView } from "./internal/scroll"
import { HasSlotController } from "./internal/slot"
import type { SlInput, SlOption } from "@shoelace-style/shoelace"

const escapeRegExp = (text) => text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")

@customElement("sl-autocomplete")
export class SlAutocomplete extends LitElement {
  static styles = css`
    :host {
      box-sizing: border-box;
    }
    :host *,
    :host *::before,
    :host *::after {
      box-sizing: inherit;
    }
    [hidden] {
      display: none !important;
    }

    :host {
      display: inline-block;
      width: 100%;
    }

    /** The popup */
    .autocomplete {
      flex: 1 1 auto;
      display: inline-flex;
      width: 100%;
      position: relative;
      vertical-align: middle;
    }
    .autocomplete::part(popup) {
      z-index: var(--sl-z-index-dropdown);
    }
    .autocomplete[data-current-placement^="top"]::part(popup) {
      transform-origin: bottom;
    }
    .autocomplete[data-current-placement^="bottom"]::part(popup) {
      transform-origin: top;
    }
    /* Combobox */
    .autocomplete__combobox {
      width: 100%;
    }
    .autocomplete__display-input {
      position: relative;
      width: 100%;
      font: inherit;
      border: none;
      background: none;
      cursor: inherit;
      overflow: hidden;
      padding: 0;
      margin: 0;
      -webkit-appearance: none;
    }
    .autocomplete__display-input:focus {
      outline: none;
    }
    .autocomplete__value-input {
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      opacity: 0;
      z-index: -1;
    }
    .autocomplete__tags {
      display: flex;
      flex: 1;
      align-items: center;
      flex-wrap: wrap;
      margin-inline-start: var(--sl-spacing-2x-small);
    }
    .autocomplete__tags::slotted(sl-tag) {
      cursor: pointer !important;
    }
    .autocomplete--disabled .autocomplete__tags,
    .autocomplete--disabled .autocomplete__tags::slotted(sl-tag) {
      cursor: not-allowed !important;
    }

    /* Listbox */
    .autocomplete__listbox,
    .autocomplete__loading-text,
    .autocomplete__empty-text {
      display: block;
      position: relative;
      font-family: var(--sl-font-sans);
      font-size: var(--sl-font-size-medium);
      font-weight: var(--sl-font-weight-normal);
      box-shadow: var(--sl-shadow-large);
      background: var(--sl-panel-background-color);
      border: solid var(--sl-panel-border-width) var(--sl-panel-border-color);
      border-radius: var(--sl-border-radius-medium);
      padding-block: var(--sl-spacing-x-small);
      padding-inline: 0;
      overflow: auto;
      overscroll-behavior: none;
      /* Make sure it adheres to the popup's auto size */
      max-width: var(--auto-size-available-width);
      max-height: var(--auto-size-available-height);
    }
    .autocomplete__listbox::slotted(sl-divider) {
      --spacing: var(--sl-spacing-x-small);
    }
    .autocomplete__listbox::slotted(small) {
      font-size: var(--sl-font-size-small);
      font-weight: var(--sl-font-weight-semibold);
      color: var(--sl-color-neutral-500);
      padding-block: var(--sl-spacing-x-small);
      padding-inline: var(--sl-spacing-x-large);
    }
  `

  @query("slot:not([name])") defaultSlot: HTMLSlotElement

  private readonly hasSlotController = new HasSlotController(this, "loading-text", "empty-text")

  private displayChanges: boolean[] = []

  @state() private value: string = ""
  @state() private hasFocus: boolean = false
  @state() currentOption: SlOption | null

  @property({ type: String, reflect: true }) emptyText: String

  @property({ type: Boolean, reflect: true }) loading: Boolean = false

  @property({ type: String, reflect: true }) loadingText: String

  @property({ type: Boolean, reflect: true }) autofilter: Boolean = false

  @property({ type: Boolean, reflect: true }) highlight: Boolean = false

  @property({ type: Number, reflect: true }) threshold: number = 1

  @property({ type: String, reflect: true }) placement: "top" | "bottom" = "bottom"

  @property({ type: Boolean }) hoist = false

  connectedCallback() {
    super.connectedCallback()
    this.handleDocumentMouseDown = this.handleDocumentMouseDown.bind(this)
  }

  disconnectedCallback() {
    this.removeOpenListeners()
  }

  private addOpenListeners() {
    document.addEventListener("mousedown", this.handleDocumentMouseDown)
  }

  private removeOpenListeners() {
    document.removeEventListener("mousedown", this.handleDocumentMouseDown)
  }

  handleSlInput(event: CustomEvent) {
    const { value } = event.target as SlInput

    if (this.autofilter) {
      this.options.forEach((option) => {
        const shouldDisplay = new RegExp(`(${escapeRegExp(value ?? "")})`, "ig").test(option.getTextLabel())

        if (shouldDisplay) {
          option.style.display = "block"
          option.disabled = false
          option.ariaHidden = "false"
        } else {
          option.style.display = "none"
          option.disabled = true
          option.ariaHidden = "true"
        }
      })
    }

    this.hasFocus = true
    this.value = value
  }

  handleKeydown(event: KeyboardEvent) {
    if (!this.shouldDisplayAutoComplete || event.ctrlKey || event.metaKey) {
      return
    }

    const options = this.visibleOptions

    if (options.length === 0) {
      return
    }

    const currentIndex = this.currentOption ? options.indexOf(this.currentOption) : -1
    const nextItem = options[currentIndex + 1] || options[0]
    const prevItem = options[currentIndex - 1] || options[options.length - 1]

    switch (event.key) {
      case "Enter":
        event.preventDefault()
        this.select(this.currentOption)
        break

      case "Tab":
      case "Escape":
        this.hasFocus = false
        break

      case "ArrowDown":
        event.preventDefault()
        this.setCurrentOption(nextItem)
        break

      case "ArrowUp":
        event.preventDefault()
        this.setCurrentOption(prevItem)
        break

      default:
        this.clearSelection()
        this.currentOption = null
    }
  }

  handleSlFocus(_event: CustomEvent) {
    if (this.value.length >= this.threshold) {
      this.hasFocus = true
    }
  }

  handleDocumentMouseDown(event: MouseEvent) {
    // Close when clicking outside of the select
    const path = event.composedPath()
    if (this && !path.includes(this)) {
      this.hide()
    }
  }

  handleSlBlur(_event: CustomEvent) {
    this.clearSelection()
    this.currentOption = null
  }

  handleOptionClick({ target }: Event) {
    this.select(target as SlOption)
  }

  show() {
    this.hasFocus = true
  }

  hide() {
    this.hasFocus = false
  }

  reset() {
    this.clearSelection()
    this.currentOption = null
    this.value = ""
  }

  select(option: SlOption) {
    if (option === null) {
      return
    }

    const event = new CustomEvent("sl-select", {
      bubbles: true,
      cancelable: false,
      composed: true,
      detail: { option },
    })

    this.dispatchEvent(event)
    this.reset()
  }

  private setCurrentOption(option: SlOption) {
    this.clearSelection()

    this.currentOption = option
    option.current = true
    option.tabIndex = 0
    option.focus()
    scrollIntoView(option, this.defaultSlot)
  }

  private clearSelection() {
    this.options.forEach((el) => {
      el.current = false
      el.tabIndex = -1
    })
  }

  get options(): SlOption[] {
    return (this.defaultSlot?.assignedElements() || []) as SlOption[]
  }

  get visibleOptions() {
    return this.options.filter((option) => option.style.display !== "none")
  }

  get hasResults() {
    return this.visibleOptions.length > 0
  }

  get shouldDisplayLoadingText(): boolean {
    return this.loading && (!!this.loadingText || this.hasSlotController.test("loading-text"))
  }

  get shouldDisplayEmptyText(): boolean {
    return !this.hasResults && (!!this.emptyText || this.hasSlotController.test("empty-text"))
  }

  get shouldDisplayAutoComplete(): boolean {
    return (
      this.hasFocus &&
      ((this.value.length >= this.threshold && this.hasResults) ||
        this.shouldDisplayLoadingText ||
        this.shouldDisplayEmptyText)
    )
  }

  updated() {
    this.displayChanges = [this.displayChanges.at(-1), this.shouldDisplayAutoComplete]
    const [prev, next] = this.displayChanges

    if (!prev && next) {
      this.addOpenListeners()
    } else if (prev && !next) {
      this.removeOpenListeners()
    }
  }

  render() {
    const { shouldDisplayLoadingText } = this

    return html`
      <div part="base">
        <sl-popup
          class=${classMap({
            autocomplete: true,
            "autocomplete--standard": true,
            "autocomplete--focused": this.hasFocus,
          })}
          placement=${this.placement}
          strategy=${this.hoist ? "fixed" : "absolute"}
          flip
          shift
          sync="width"
          auto-size="vertical"
          auto-size-padding="10"
          ?active=${this.shouldDisplayAutoComplete}
        >
          <div
            part="combobox"
            class="autocomplete__combobox"
            slot="anchor"
            @sl-focus=${this.handleSlFocus}
            @sl-input=${this.handleSlInput}
            @keydown=${this.handleKeydown}
          >
            <slot name="trigger"></slot>
          </div>

          <div
            part="loading-text"
            id="loading-text"
            class="autocomplete__loading-text"
            aria-hidden=${shouldDisplayLoadingText ? "false" : "true"}
            style="${styleMap({ display: shouldDisplayLoadingText ? "block" : "none" })}"
          >
            <slot name="loading-text">${this.loadingText}</slot>
          </div>

          <div
            part="empty-text"
            id="empty-text"
            class="autocomplete__empty-text"
            aria-hidden=${this.shouldDisplayEmptyText ? "false" : "true"}
            style="${styleMap({ display: this.shouldDisplayEmptyText ? "block" : "none" })}"
          >
            <slot name="empty-text">${this.emptyText}</slot>
          </div>

          <slot
            id="listbox"
            role="listbox"
            class="autocomplete__listbox"
            aria-expanded=${shouldDisplayLoadingText ? "true" : "false"}
            aria-hidden=${shouldDisplayLoadingText ? "true" : "false"}
            style="${styleMap({ display: shouldDisplayLoadingText ? "none" : "block" })}"
            @keydown=${this.handleKeydown}
            @click=${this.handleOptionClick}
          >
          </slot>
        </sl-popup>
      </div>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "sl-autocomplete": SlAutocomplete
  }
}
