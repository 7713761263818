import SlOption from "@shoelace-style/shoelace/dist/components/option/option.component.js"
import "custom_elements/sl-nav-link"
import "custom_elements/sl-autocomplete"
import "controllers"

class SlOptionWithoutValueOverride extends SlOption {
  handleValueChange() {
    /* no-op */
  }
}

customElements.define("sl-option", SlOptionWithoutValueOverride)
