import { Controller } from "@hotwired/stimulus"
import type { SlDialog } from "@shoelace-style/shoelace"

export class SlDialogController extends Controller {
  static targets = ["dialog"]

  declare readonly dialogTarget: SlDialog

  open(event: PointerEvent) {
    if (!event.metaKey) {
      event.preventDefault()
      this.dialogTarget.show()
    }
  }
}
