import { Controller } from "@hotwired/stimulus"
import { useRender, html } from "stimulus-lit"
import Dashboard from "@uppy/dashboard"
import ImageEditor from "@uppy/image-editor"
import Uppy from "@uppy/core"
import type { SlAvatar } from "@shoelace-style/shoelace"
import { ActiveStorageUpload } from "../packages/uppy-activestorage-upload"

export interface ActiveStorageResponse {
  body: {}
  signed_id: string
  status: number
  uploadURL: string
  preview: string
}

export class FileUploadController extends Controller<HTMLElement> {
  static targets = ["avatar"]

  static values = {
    directUploadUrl: String,
    inputName: String,
    signedId: String,
    targetId: String,
  }

  declare readonly avatarTarget: SlAvatar
  declare directUploadUrlValue: string
  declare inputNameValue: string
  declare targetIdValue: string
  declare signedIdValue: string

  connect() {
    useRender(this)

    const uppy = new Uppy({
      autoProceed: true,
      restrictions: {
        allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png", ".gif"],
        maxNumberOfFiles: 1,
      },
    })

    uppy.use(Dashboard, { trigger: this.targetIdValue, showProgressDetails: true })
    uppy.use(ImageEditor, { target: Dashboard, quality: 0.9 })
    uppy.use(ActiveStorageUpload, { directUploadUrl: this.directUploadUrlValue })
    uppy.on("complete", ({ successful }) => {
      this.avatarTarget.image = successful[0].preview
      this.signedIdValue = (successful[0].response as ActiveStorageResponse).signed_id
    })
  }

  ignore(event: PointerEvent) {
    event.preventDefault()
  }

  render() {
    return this.signedIdValue
      ? html`<input type="hidden" name=${this.inputNameValue} value=${this.signedIdValue} />`
      : null
  }
}
