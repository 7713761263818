export const initializeService = (apiToken = undefined) => {
  if (typeof window.google !== "undefined") {
    return
  }

  if (apiToken) {
    const script = document.createElement("script")
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiToken}&libraries=places`

    document.body.appendChild(script)
  } else {
    console.warn("Google Maps API token is missing. Injecting a fake service.")

    window.google = {
      maps: {
        places: {
          // @ts-ignore
          AutocompleteService: class FakeAutocompleteService {
            getPlacePredictions = () =>
              Promise.resolve({
                predictions: [
                  {
                    description: "Roppongi Hills, 6 Chome-10-1 Roppongi, Minato City, Tokyo, Japan",
                    matched_substrings: [
                      {
                        length: 14,
                        offset: 0,
                      },
                      {
                        length: 4,
                        offset: 24,
                      },
                    ],
                    place_id: "ChIJM9xJEHeLGGARAshMWUgC_ls",
                    reference: "ChIJM9xJEHeLGGARAshMWUgC_ls",
                    structured_formatting: {
                      main_text: "Roppongi Hills",
                      main_text_matched_substrings: [
                        {
                          length: 14,
                          offset: 0,
                        },
                      ],
                      secondary_text: "6 Chome-10-1 Roppongi, Minato City, Tokyo, Japan",
                      secondary_text_matched_substrings: [
                        {
                          length: 4,
                          offset: 8,
                        },
                      ],
                    },
                    terms: [
                      {
                        offset: 0,
                        value: "Roppongi Hills",
                      },
                      {
                        offset: 16,
                        value: "6 Chome",
                      },
                      {
                        offset: 24,
                        value: "10-1",
                      },
                      {
                        offset: 29,
                        value: "Roppongi",
                      },
                      {
                        offset: 39,
                        value: "Minato City",
                      },
                      {
                        offset: 52,
                        value: "Tokyo",
                      },
                      {
                        offset: 59,
                        value: "Japan",
                      },
                    ],
                    types: ["tourist_attraction", "point_of_interest", "establishment"],
                  },
                  {
                    description: "Roppongi Hills Mori Tower, 6 Chome-10-1 Roppongi, Minato City, Tokyo, Japan",
                    matched_substrings: [
                      {
                        length: 14,
                        offset: 0,
                      },
                      {
                        length: 4,
                        offset: 35,
                      },
                    ],
                    place_id: "ChIJ__9ThIKLGGAR2FCxue47YLg",
                    reference: "ChIJ__9ThIKLGGAR2FCxue47YLg",
                    structured_formatting: {
                      main_text: "Roppongi Hills Mori Tower",
                      main_text_matched_substrings: [
                        {
                          length: 14,
                          offset: 0,
                        },
                      ],
                      secondary_text: "6 Chome-10-1 Roppongi, Minato City, Tokyo, Japan",
                      secondary_text_matched_substrings: [
                        {
                          length: 4,
                          offset: 8,
                        },
                      ],
                    },
                    terms: [
                      {
                        offset: 0,
                        value: "Roppongi Hills Mori Tower",
                      },
                      {
                        offset: 27,
                        value: "6 Chome",
                      },
                      {
                        offset: 35,
                        value: "10-1",
                      },
                      {
                        offset: 40,
                        value: "Roppongi",
                      },
                      {
                        offset: 50,
                        value: "Minato City",
                      },
                      {
                        offset: 63,
                        value: "Tokyo",
                      },
                      {
                        offset: 70,
                        value: "Japan",
                      },
                    ],
                    types: ["shopping_mall", "travel_agency", "point_of_interest", "establishment"],
                  },
                  {
                    description: "Tokyo City View, 6 Chome-10-1 Roppongi, Minato City, Tokyo, Japan",
                    matched_substrings: [
                      {
                        length: 15,
                        offset: 0,
                      },
                      {
                        length: 4,
                        offset: 25,
                      },
                    ],
                    place_id: "ChIJK0TdDneLGGARVxJ3MHC0emY",
                    reference: "ChIJK0TdDneLGGARVxJ3MHC0emY",
                    structured_formatting: {
                      main_text: "Tokyo City View",
                      main_text_matched_substrings: [
                        {
                          length: 15,
                          offset: 0,
                        },
                      ],
                      secondary_text: "6 Chome-10-1 Roppongi, Minato City, Tokyo, Japan",
                      secondary_text_matched_substrings: [
                        {
                          length: 4,
                          offset: 8,
                        },
                      ],
                    },
                    terms: [
                      {
                        offset: 0,
                        value: "Tokyo City View",
                      },
                      {
                        offset: 17,
                        value: "6 Chome",
                      },
                      {
                        offset: 25,
                        value: "10-1",
                      },
                      {
                        offset: 30,
                        value: "Roppongi",
                      },
                      {
                        offset: 40,
                        value: "Minato City",
                      },
                      {
                        offset: 53,
                        value: "Tokyo",
                      },
                      {
                        offset: 60,
                        value: "Japan",
                      },
                    ],
                    types: ["tourist_attraction", "landmark", "point_of_interest", "establishment"],
                  },
                  {
                    description: "Roppongi Hills Gate Tower Residence, 6 Chome-11 Roppongi, Minato City, Tokyo, Japan",
                    matched_substrings: [
                      {
                        length: 14,
                        offset: 0,
                      },
                    ],
                    place_id: "ChIJ4R8fAXaLGGARt8x1W9nezr8",
                    reference: "ChIJ4R8fAXaLGGARt8x1W9nezr8",
                    structured_formatting: {
                      main_text: "Roppongi Hills Gate Tower Residence",
                      main_text_matched_substrings: [
                        {
                          length: 14,
                          offset: 0,
                        },
                      ],
                      secondary_text: "6 Chome-11 Roppongi, Minato City, Tokyo, Japan",
                    },
                    terms: [
                      {
                        offset: 0,
                        value: "Roppongi Hills Gate Tower Residence",
                      },
                      {
                        offset: 37,
                        value: "6 Chome",
                      },
                      {
                        offset: 45,
                        value: "11",
                      },
                      {
                        offset: 48,
                        value: "Roppongi",
                      },
                      {
                        offset: 58,
                        value: "Minato City",
                      },
                      {
                        offset: 71,
                        value: "Tokyo",
                      },
                      {
                        offset: 78,
                        value: "Japan",
                      },
                    ],
                    types: ["premise", "geocode"],
                  },
                  {
                    description: "Roppongi Hills Club, 6 Chome-10-1 Roppongi, Minato City, Tokyo, Japan",
                    matched_substrings: [
                      {
                        length: 14,
                        offset: 0,
                      },
                      {
                        length: 4,
                        offset: 29,
                      },
                    ],
                    place_id: "ChIJtbKhzOWLGGARJB9RTuXKRa0",
                    reference: "ChIJtbKhzOWLGGARJB9RTuXKRa0",
                    structured_formatting: {
                      main_text: "Roppongi Hills Club",
                      main_text_matched_substrings: [
                        {
                          length: 14,
                          offset: 0,
                        },
                      ],
                      secondary_text: "6 Chome-10-1 Roppongi, Minato City, Tokyo, Japan",
                      secondary_text_matched_substrings: [
                        {
                          length: 4,
                          offset: 8,
                        },
                      ],
                    },
                    terms: [
                      {
                        offset: 0,
                        value: "Roppongi Hills Club",
                      },
                      {
                        offset: 21,
                        value: "6 Chome",
                      },
                      {
                        offset: 29,
                        value: "10-1",
                      },
                      {
                        offset: 34,
                        value: "Roppongi",
                      },
                      {
                        offset: 44,
                        value: "Minato City",
                      },
                      {
                        offset: 57,
                        value: "Tokyo",
                      },
                      {
                        offset: 64,
                        value: "Japan",
                      },
                    ],
                    types: ["restaurant", "point_of_interest", "food", "establishment"],
                  },
                ],
                status: "OK",
              })
          },
        },
      },
    }
  }
}

export const getPlacePredictions = async (input) => {
  return await new window.google.maps.places.AutocompleteService().getPlacePredictions({ input })
}
