import { Controller } from "@hotwired/stimulus"
import { useRender, html } from "stimulus-lit"
import type { SlInput, SlOption } from "@shoelace-style/shoelace"
import type { SlAutocomplete } from "custom_elements/sl-autocomplete"

export interface Option {
  value: string
  label: string
}

export class MultiInputController extends Controller<HTMLElement> {
  static targets = ["input", "autocomplete"]

  static values = {
    selectedItems: Array,
    name: String,
  }

  declare readonly inputTarget: SlInput
  declare readonly autocompleteTarget: SlAutocomplete
  declare readonly hasAutocompleteTarget: boolean
  declare selectedItemsValue: Array<Option>
  declare nameValue: string

  connect() {
    useRender(this)
  }

  addDataValue(event: CustomEvent) {
    const { value } = (event.target as HTMLElement).dataset

    this.selectedItemsValue = [...this.selectedItemsValue, { value, label: value }]
  }

  addInputValue(event: CustomEvent<{ option: SlOption }>) {
    const { value } = event.detail?.option || this.inputTarget

    if (value.length === 0) {
      return
    }

    this.inputTarget.value = ""
    this.inputTarget.focus()

    if (this.hasAutocompleteTarget) {
      this.autocompleteTarget.reset()
    }

    this.selectedItemsValue = [...this.selectedItemsValue, { value, label: value }]
  }

  addInputValueByEnter(event: KeyboardEvent) {
    if (event.defaultPrevented) {
      return // Do nothing if the event was already processed
    }

    if (event.key === "Enter") {
      event.preventDefault()
      event.stopPropagation()
      this.addInputValue({} as any)
    }
  }

  removeItem = (event: PointerEvent, index) => {
    this.selectedItemsValue = this.selectedItemsValue.filter((_, theIndex) => theIndex !== index)
  }

  render() {
    return this.selectedItemsValue.map(
      ({ value, label }, index) => html`
        <div class="flex items-center">
          <span class="grow mx-2 text-sm"> ${label} </span>

          <sl-icon-button label="Remove ${label}" name="trash-fill" @click=${(event) => this.removeItem(event, index)}>
          </sl-icon-button>
          <input type="hidden" name=${this.nameValue} value=${value} />
        </div>
        ${index !== this.selectedItemsValue.length - 1 ? html`<sl-divider class="my-2"></sl-divider>` : ""}
      `
    )
  }
}
